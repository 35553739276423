import React from 'react'

const LandingPage = () => {
    return (
        <div style={{color: 'red'}}>
            This is a landing page,  it's very COOL. VERY COOL
        </div>
    )
}

export default LandingPage
